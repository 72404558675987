
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  getProductOfSeller2,
  getEventsWithProducts,
  removeProduct,
} from '@/services/seller.service'
import Products from '@/components/products/Administration/productsReadOnly.vue'
import { ErrorMessages } from '@/helpers/constants'
@Component({
  components: {
    Products,
  },
})
export default class eventsReadOnly extends Vue {
  @Prop() events!: any

  sellersEventProduct: [] = []
  sellersProductFiltered: [] = []
  sellersProductTotalPages = 1
  sellersProductPage = 1
  filter = ''
  sellerId: number = 0
  loading = false
  eventsWithProducts: any = []
  eventsWithProductsFilter = []
  searchQuery: string = ''
  viewSellerInProducts = 0
  isCollapsed = true
  isSelected: any = {}
  icon = false

  page = 1
  totalPages = 1

  ocupate(totalTicket: number | null) {
    return totalTicket || 0
  }

  async getProductOfSeller(sellerId: number) {
    try {
      this.loading = true
      const { data } = await getProductOfSeller2(sellerId)
      this.sellersEventProduct = data
      console.log(data)
      this.loading = false
    } catch (error) {
      console.error(error)
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  toggleCollapse(event) {
    event.showCollapse = !event.showCollapse
  }

  changeIcon(event) {
    const icon: any = document.getElementById(event.id)

    const arrowRight = icon.classList.contains(
      'mdi-arrow-right-drop-circle-outline'
    )
    const arrowDown = icon.classList.contains(
      'mdi-arrow-down-drop-circle-outline'
    )

    if (arrowRight) {
      icon.classList.remove('mdi-arrow-right-drop-circle-outline')
      icon.classList.add('mdi-arrow-down-drop-circle-outline')
    }
    if (arrowDown) {
      icon.classList.remove('mdi-arrow-down-drop-circle-outline')
      icon.classList.add('mdi-arrow-right-drop-circle-outline')
    }
  }

  changeState(event) {
    this.isSelected = event
  }

  async updatePagination(page) {
    const events_products = await getEventsWithProducts(this.sellerId, page)

    this.eventsWithProducts = events_products.data
    this.eventsWithProductsFilter = events_products.data

    this.$router.push({ query: { page: page } })
  }

  async getEventsWithProducts(sellerId: number) {
    let page =
      this.$route.query.page != undefined ? this.$route.query.page : false
    const response = await getEventsWithProducts(sellerId, page)
    return response
  }

  newData(data) {
    this.$emit('newData', data)
  }

  products(data) {
    this.$emit('products', data)
  }

  async removeProduct(product, _) {
    const result = await removeProduct(product.id, this.sellerId)

    if (result.message == 'vendedor desajuntado con exito') {
      product.associated = false
    }

    await this.getProductOfSeller(this.sellerId)
  }

  async search(_) {
    this.eventsWithProductsFilter = this.eventsWithProducts.filter()
  }

  async mounted() {
    if (this.$route.params.sellerId) {
      this.sellerId = parseInt(this.$route.params.sellerId)
      console.log(this.sellerId)
      await this.getProductOfSeller(this.sellerId)

      const events_products = await this.getEventsWithProducts(this.sellerId)

      this.eventsWithProducts = events_products.data
      this.eventsWithProductsFilter = events_products.data
      this.page = events_products.page
      this.totalPages = events_products.total
    }
    //this.getSellers();
  }
}
