
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  getProductOfSeller2,
  getEventsWithProducts,
  removeProduct,
  addProduct,
} from '@/services/seller.service'
import { isoDateToDay, isoDateToMonth, isoDateToHour } from '@/helpers/date'
@Component
export default class Products extends Vue {
  @Prop() products!: any
  @Prop() sellerId!: number
  @Prop() isSelected!: any
  @Prop() eventId: any
  array = []

  public total_products_sold_type = null

  mounted() {
    /*       const newProducts = this.products;

        const ids = newProducts.map((res) => {

        }) */

    /*         this.products.map((product) => {
          product.selected = this.isSelected;
          return product
        })

        console.log(this.products) */

    this.array.push(this.products)

    console.log('ARRAY', this.isSelected)
  }

  @Watch('isSelected.selected')
  selectChange() {
    console.log(this.isSelected, 'edjnfc')
    this.products = this.products.map(product => {
      if (this.isSelected.id == product.event_id) {
        product.selected = this.isSelected.selected
      }
      return product
    })

    this.isSelected = {}

    console.log(this.products)
  }

  ocupate(totalTicket: number | null) {
    return totalTicket || 0
  }

  dateToHours(isoDate: string) {
    return isoDateToHour(isoDate)
  }

  productState() {
    this.$emit('products', this.products)
  }

  async addProduct(product) {
    const result = await addProduct(product.id, this.sellerId)

    if (result.message == 'vendedor adjuntado con exito') {
      product.associated = true
    }

    const { data } = await getProductOfSeller2(this.sellerId)

    this.$emit('newData', data)
  }

  async removeProduct(product) {
    const result = await removeProduct(product.id, this.sellerId)

    if (result.message == 'vendedor desajuntado con exito') {
      product.associated = false
    }

    const { data } = await getProductOfSeller2(this.sellerId)

    this.$emit('newData', data)
  }
}
