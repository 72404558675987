
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import EventsSelection from '@/components/products/Administration/eventsSelection.vue'
import { Validate } from 'vuelidate-property-decorators'
import { minLength, sameAs } from 'vuelidate/lib/validators'
import axios from '@/services/base.service'

@Component({
  components: {
    SellerHeader,
    EventsSelection,
  },
})
export default class ProductsBySellerModal extends Vue {
  seller: UserInterface = {}
  @Validate({ minLength: minLength(8) }) password = ''
  @Validate({ sameAsPassword: sameAs('password') }) passwordRepeat = ''
  @Prop() location: string

  userId = ''
  title = 'Crear vendedor'
  editMode = false
  options: [] = []
  business_id: any = ''
  passwordRepeat = ''
  events!: any
  relatedProductsOfLocation = null
  isLoading = false
  products = []

  save() {
    const filteredProducts = this.events.flatMap(event =>
      event.products.filter(product => product.selected)
    )
    this.$emit('addProductsToSellerEvent', { products: filteredProducts })
  }

  mounted() {
    this.getProductsOfLocation()
  }

  get isDisabled() {
    if (this.seller.fullname && this.seller.email && this.location) {
      return false
    } else {
      return true
    }
  }

  async getProductsOfLocation() {
    this.isLoading = true

    const {
      data: { data },
    } = await axios.get(`/locations/get-products/${this.location}`)
    this.relatedProductsOfLocation = data.length == 0 ? null : data

    const result = data.map(res => {
      const products = res.products.map(product => {
        return { ...product, selected: true }
      })
      return {
        ...res,
        show: products[0]?.suscribe == null ? true : false,
        selected: true,
        products: products,
      }
    })

    this.events = result
    this.isLoading = false
    console.log('this.isLoading ', this.isLoading)
  }
}
