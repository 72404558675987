
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import {
  getLocationById,
  removeSellerFromLocationById,
  addSellerToLocationById,
  getSellersNotInLocation,
  addProductsFromLocationToSeller,
} from '@/services/locations.service'
import { Location } from '@/interfaces/locations.interface'
import { Seller } from '@/interfaces/ticket.interface'
import ProductsBySellerReadOnlyModal from '@/components/Administration/modal/ProductsBySellerReadOnlyModal.vue'
import ProductsBySellerModal from '@/components/Administration/modal/ProductsBySellerModal.vue'
import _ from 'lodash'

@Component({
  components: {
    ProductsBySellerReadOnlyModal,
    ProductsBySellerModal,
    SellerHeader,
  },
})
export default class ListSellersByLocation extends Vue {
  location!: Location
  locationId: string = ''
  headers = [
    { text: 'ID', value: 'id' },
    { text: 'Seller', value: 'seller' },
    { text: 'Email', value: 'email' },
    { text: '', value: 'actions' },
  ]

  sellerOptions: Seller[] = []
  items: Seller[] = []
  selectedSeller = null
  selectedSellerLocation = null
  timeout!: number
  showDeleteConfirmation = false
  showStep1 = false
  showStep2 = false
  sellerToDeleteId: string = ''
  sellerSearch = ''
  unasignedSellerSearch: string = ''
  itemsLoading = false
  unasignedSellerLoading = false

  async getAllSellers() {
    this.unasignedSellerLoading = true
    this.sellerOptions = await getSellersNotInLocation(this.locationId)
    this.unasignedSellerLoading = false
  }

  async addSellerToLocation(sellerId: string) {
    try {
      const { message } = await addSellerToLocationById(
        this.locationId,
        sellerId
      )

      Vue.$toast.success(message)
      this.getAll()
      this.selectedSeller = null
    } catch (error) {
      console.error(error)
    }
  }

  async addProductsToseller(data) {
    try {
      this.showStep1 = false
      this.showStep2 = false

      const _data = {
        seller_id: this.selectedSeller,
        products: data.products.map(product => product.id),
      }

      const { message } = await addProductsFromLocationToSeller(
        this.locationId,
        _data.seller_id.toString(),
        _data.products
      )
      Vue.$toast.success(message)
      this.getAll()
    } catch (error) {
      console.error(error)
    }
  }

  async getLocationByIdWithSellers() {
    this.itemsLoading = true
    this.location = await getLocationById(this.locationId, true)
    this.items = this.location?.sellers || []
    this.itemsLoading = false
  }

  async removeSellerFromLocation() {
    try {
      await removeSellerFromLocationById(this.locationId, this.sellerToDeleteId)
      this.getAll()
      Vue.$toast.success('El vendedor fue eliminado exitosamente del local')
    } catch (error) {
      console.error(error)
    }
  }
  async showDeleteModal(sellerId: string) {
    try {
      this.sellerToDeleteId = sellerId
      this.showDeleteConfirmation = true
    } catch (error) {
      console.error(error)
    }
  }

  showStep2Modal() {
    try {
      this.showStep1 = false
      this.showStep2 = true
    } catch (error) {
      console.error(error)
    }
  }

  async showStep1Modal(item: Seller) {
    try {
      this.selectedSeller = item.id
      const { location } = item

      if (location && (location as Location[]).length) {
        this.selectedSellerLocation = location[0].id
        this.showStep1 = true
        return
      }

      this.showStep2 = true
    } catch (error) {
      console.error(error)
    }
  }

  cancelStep1() {
    this.showStep1 = false
  }

  cancelDelete() {
    this.showDeleteConfirmation = false
    this.sellerToDeleteId = ''
  }
  mounted() {
    this.locationId = this.$route.params.id
    this.getAll()
  }

  getAll() {
    this.getLocationByIdWithSellers()
    this.getAllSellers()
  }
}
