import { CreateLocation, Location } from "@/interfaces/locations.interface";
import { Vue } from "vue-property-decorator";
import axios from "@/services/base.service";
import { Seller } from "@/interfaces/ticket.interface";
import { Product } from "@/interfaces/product.interface";

const resource = "locations";

export const createLocation = async (location: CreateLocation): Promise<Location> => {
    try {
        const { data: { data } } = await axios.post(resource, location);
        Vue.$toast.success("Local creado exitosamente.");
        return data;
    } catch (error) {
        console.error(error);
        Vue.$toast.error("Ha ocurrido un problema descargando el recurso");

        return error
    }
};


export const updateLocation = async (id: string, location: CreateLocation): Promise<Location> => {
    try {
        const { data: { data } } = await axios.put(`${resource}/${id}`, location);
        Vue.$toast.success("Local actualizado exitosamente.");
        return data;
    } catch (error) {
        console.error(error);
        Vue.$toast.error("Ha ocurrido un problema descargando el recurso");

        return error
    }
};

export const getLocations = async (getWithSellers?: boolean): Promise<Location[]> => {
    try {
        const queryParams = getWithSellers ? `?sellers=${getWithSellers}` : '';
        const { data: { data } } = await axios.get(`${resource}${queryParams}`);
        return data;
    } catch (error) {
        console.error(error);
        Vue.$toast.error("Ha ocurrido un problema descargando el recurso");
    }
};

export const getLocationById = async (id: string, getWithSellers?: boolean): Promise<Location> => {
    try {
        const queryParams = getWithSellers ? `?sellers=${getWithSellers}` : '';
        const { data: { data } } = await axios.get(`${resource}/${id}${queryParams}`);
        return data;
    } catch (error) {
        console.error(error);
        Vue.$toast.error("Ha ocurrido un problema descargando el recurso");
    }
};

export const removeSellerFromLocationById = async (locationId: string, sellerId: string): Promise<Location> => {
    try {
        const { data: { data } } = await axios.patch(`${resource}/${locationId}/remove-seller`, { seller_id: sellerId });
        return data;
    } catch (error) {
        console.error(error);
        Vue.$toast.error("Ha ocurrido un problema descargando el recurso");
    }
};

export const searchSellerByName = async (locationId: string, name?: string): Promise<Seller[]> => {
    try {
        const queryParams = name ? `?name=${name}` : '';
        const { data: { data } } = await axios.get(`${resource}/${locationId}/search-sellers${queryParams}`);
        return data;
    } catch (error) {
        console.error(error);
        Vue.$toast.error("Ha ocurrido un problema descargando el recurso");
    }
};

export const getSellersNotInLocation = async (locationId: string): Promise<Seller[]> => {
    try {
        const { data: { data } } = await axios.get(`${resource}/${locationId}/search-sellers`);
        return data;
    } catch (error) {
        console.error(error);
        Vue.$toast.error("Ha ocurrido un problema descargando el recurso");
    }
};

export const addSellerToLocationById = async (locationId: string, sellerId: string): Promise<{ message: string }> => {
    try {
        const { data } = await axios.patch(`${resource}/${locationId}/add-seller`, { seller_id: sellerId });

        return data;
    } catch (error) {
        console.error(error);
        Vue.$toast.error("Ha ocurrido un problema descargando el recurso");
    }
};

export const addProductsFromLocationToSeller = async (locationId: string, sellerId: string, products: Product[]): Promise<{ message: string }> => {
    try {
        const { data } = await axios.patch(`${resource}/${locationId}/change-sellers-of-location`, { seller_id: sellerId, products });

        return data;
    } catch (error) {
        console.error(error);
        Vue.$toast.error("Ha ocurrido un problema descargando el recurso");
    }
};
